import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import ButtonGroup from "@mui/material/ButtonGroup";
import repathlogo from "../../../assets/repathLogo.png";

const DetailHero = (): JSX.Element => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'relative',
                backgroundColor: theme.palette.alternate.main,
                backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
                paddingTop: 5,
            }}
        >
            <Container>
                <Box>
                    <Box
                        marginBottom={{ xs: 0, sm: 4 }}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Box
                            component={'img'}
                            src={repathlogo}
                            alt={'Dminder Logo'}
                            width={0.075}
                        />
                        <Typography
                            variant="h3"
                            paddingTop={3}
                            gutterBottom
                            align={'center'}
                            sx={{
                                fontWeight: 900,
                            }}
                        >
                            RePath
                        </Typography>
                        <Typography
                            variant="h6"
                            component="p"
                            color="text.primary"
                            align={'center'}
                            sx={{ marginBottom: 2 }}
                        >
                            RePath is utilized to electronically assist individuals through the criminal justice process. Agencies are able to choose from multiple supervision levels depending on specific client needs.
                        </Typography>
                        <ButtonGroup variant="text" aria-label="text button group">
                            <Button
                                href="https://ehawksolutions.com/repath/"
                            >
                                Website
                            </Button>
                            <Button
                                href={'https://apps.apple.com/us/app/repath/id1443603077?platform=iphone'}
                            >
                                App Store
                            </Button>
                            <Button
                                href={'https://play.google.com/store/apps/details?id=com.ehawk.repath&hl=en_US&gl=US'}>
                                Google Play
                            </Button>
                        </ButtonGroup>
                    </Box>
                    <Grid
                        container
                        spacing={2}
                        sx={{ display: { xs: 'none', sm: 'flex' } }}
                    >
                        <Grid
                            item
                            container
                            // justifyContent={'flex-end'}
                            // alignItems={'flex-end'}
                            xs={4}
                            sx={{
                                '& .lazy-load-image-loaded': {
                                    width: '80%',
                                    height: '100%',
                                    display: 'flex !important',
                                },
                            }}
                        >
                            <Box
                                component={LazyLoadImage}
                                height={1}
                                width={1}
                                borderRadius={2}
                                src={'https://is3-ssl.mzstatic.com/image/thumb/Purple123/v4/b3/8b/84/b38b844c-2ae7-4937-2a57-e7d7a1f9e271/mzl.jujyglha.jpg/460x0w.webp'}
                                alt="..."
                                effect="blur"
                                sx={{
                                    objectFit: 'cover',
                                    filter:
                                        theme.palette.mode === 'dark' ? 'brightness(0.6)' : 'none',
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            // justifyContent={'flex-end'}
                            // alignItems={'flex-end'}
                            xs={4}
                            sx={{
                                '& .lazy-load-image-loaded': {
                                    width: '80%',
                                    height: '100%',
                                    display: 'flex !important',
                                },
                            }}
                        >
                            <Box
                                component={LazyLoadImage}
                                height={1}
                                width={1}
                                borderRadius={2}
                                src={'https://is5-ssl.mzstatic.com/image/thumb/Purple113/v4/4c/d7/fe/4cd7fe3f-e21e-a787-4c27-f1c7092de0cd/mzl.ciqgriga.jpg/460x0w.webp'}
                                alt="..."
                                effect="blur"
                                sx={{
                                    objectFit: 'cover',
                                    filter:
                                        theme.palette.mode === 'dark' ? 'brightness(0.6)' : 'none',
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            // justifyContent={'flex-end'}
                            // alignItems={'flex-end'}
                            xs={4}
                            sx={{
                                '& .lazy-load-image-loaded': {
                                    width: '80%',
                                    height: '100%',
                                    display: 'flex !important',
                                },
                            }}
                        >
                            <Box
                                component={LazyLoadImage}
                                height={1}
                                width={1}
                                borderRadius={2}
                                src={'https://is5-ssl.mzstatic.com/image/thumb/Purple123/v4/40/54/10/40541026-9347-aefc-9611-ec43e39698ec/pr_source.jpg/460x0w.webp'}
                                alt="..."
                                effect="blur"
                                sx={{
                                    objectFit: 'cover',
                                    filter:
                                        theme.palette.mode === 'dark' ? 'brightness(0.6)' : 'none',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default DetailHero;